import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate, monthlySeo, experienceYears } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import { useAlternateLangs } from "../../components/Hreflangs";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";


const breadCrumbLevels = {
    Home: "/",
    "Over mij": "/nl/over",
    "SEO Freelancer": "/nl/seo-freelancer"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
    // replace with pages x-default
    "/en/seo-freelancer"
);


const SeoFreelancer = props => {

    return (
        <Layout location={props.location} alternateLangs={alternateLangs}>
            <React.Fragment>
                <SEO
                    title="SEO Freelancer inhuren: Profiel van M. Kupperschmidt"
                    description="Ik ben een freelance SEO consultant met vele jaren ervaring bij een bureau. U kunt mij inhuren om uw website naar de top van Google's rankings te brengen. Neem direct contact met mij op."
                    lang="nl"
                    image="matthias-kupperschmidt-presenting-at-founders-house-oct-2019"
                    alternateLangs={alternateLangs}
                    canonical="/nl/seo-freelancer"
                />
                <MainContent article>
                    <Img
                        src="seo-freelancer/tag-management-consultant-hero-cropped.jpg"
                        alt="Matthias Kupperschmidt praat over keyword moeilijkheid op een SEO consultants bijeenkomst in Kopenhagen, Denemarken, oktober 2019"
                    />
                    <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
                    <H as="h1">Huur een freelance SEO consultant in</H>
                    <ProfileCard
                        tags={["SEO Content", "Backlinks", "Technische SEO", "Onpage SEO", "HTML/CSS/JavaScript", "React", "Python", "EN, DE, DA"]}
                        profession="SEO Consultant"
                        rate={`vanaf ${monthlySeo}€ per maand`}
                        location="Berlijn, Duitsland / remote"
                        cta="Contact"
                    />
                    <p>Ik ben een SEO freelancer en u kunt mij inhuren om uw website naar de top van de zoekresultaten van Google te brengen.</p>
                    <p>Ik bied dezelfde diensten aan als een SEO-bureau, maar dan als onafhankelijke SEO consultant. Ik neem de planning, uitvoering en code-implementatie over of werk samen met uw webbureau.</p>
                    <p>Eerder werkte ik bij verschillende SEO-bureaus voor zakelijke klanten en e-commerce bedrijven, waarbij ik dagelijks bezig was met zoekmachineoptimalisatie.</p>
                    <p>In 2018 maakte ik de overstap om als freelance SEO consultant te gaan werken. Naast het optimaliseren van de zoekmachine rankings van Google, bouw ik ook professionele tracking setups met bijvoorbeeld Google Analytics en Google Tag Manager.</p>
                    <p>
                        U kunt mijn <a target="_blank" noopener="true" href="https://www.trustpilot.com/review/bluerivermountains.com">beoordelingen</a> lezen of meer over mijn carrière te weten komen op{" "}
                        <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
                            Linkedin.
                        </a>{" "}
                        Mijn persoonlijke verhaal en andere details vindt u op mijn <Link to="/nl/over">over mij pagina</Link>.
                    </p>
                    <br />
                    <br />
                    <H as="h6" style={{ "textAlign": "center" }}>Met wie ik heb gewerkt</H>
                    <ImgScreenshot
                        src="about/clients_700px.png"
                        alt="klanten waarmee ik heb gewerkt"
                        className="article-img"
                    />
                    <br />
                    <br />
                    <br />

                    <H as="h2" style={{ "textAlign": "center" }}>Vraag een offerte aan</H>
                    <ContactForm showHeadline={false} formName="ga consultant (EN) - contact form" />

                    <H as="h2">SEO Diensten</H>
                    <p>
                        Over het algemeen werk ik aan alle belangrijke SEO ranking factoren om websites voor zoekmachines te optimaliseren, net zoals
                        elk SEO-bedrijf.
                    </p>
                    <p>Hier een korte lijst van mijn SEO diensten:</p>
                    <ul>
                        <li>On-page & Off-page optimalisatie</li>
                        <li>Schaalbare backlink building strategieën</li>
                        <li>Content creatie in meerdere talen</li>
                        <li>JavaScript SEO</li>
                        <li>Laadsnelheid optimalisatie</li>
                        <li>Technisch SEO advies</li>
                        <li>Site structuur</li>
                        <li>Accelerated Mobile Pages (AMP)</li>
                        <li>geen lokale SEO</li>
                    </ul>
                    <p>Hoewel ik me focus op SEO, ben ik me er goed van bewust dat er naast zoekverkeer ook andere krachtige betaalde verkeersbronnen zijn, zoals Facebook, Twitter of Google ads. Daarom implementeer ik mijn SEO technieken met andere internet marketing disciplines in gedachten, zodat we <b>organisch verkeer kunnen benutten voor retargeting</b> in betaalde zoekcampagnes en social media campagnes.</p>
                    <p>Vanwege mijn kennis van webontwikkeling kan ik u ook helpen met webdesign taken in HTML, CSS en JavaScript of configuraties in Google Tag Manager en Google Analytics.</p>

                    <H as="h2">Uurtarief en kosten</H>
                    <p>Mijn tarief voor freelance SEO consultancy is <b>{hourlyRate} €</b> netto per uur. De totale kosten voor een SEO-project zijn afhankelijk van uw eigen doelen, maar ook van de huidige site-optimalisatie en de inspanningen van uw concurrenten.</p>
                    <p>Hieronder enkele factoren die de prijs van mijn SEO-advieswerk bepalen:</p>
                    <ul>
                        <li>Totaal aantal pagina's op het domein</li>
                        <li>Leeftijd en autoriteit van het domein</li>
                        <li>Concurrentiekracht van de niche</li>
                        <li>Technische uitdagingen</li>
                        <li>Aanpassingsvermogen van de frontend</li>
                        <li>Aantal talen waarvoor we optimaliseren</li>
                        <li>Hoeveel nieuwe website content hebben we nodig?</li>
                        <li>Welke linkbuilding strategie is vereist?</li>
                        <li>Zijn er andere partners die taken voor content of links uitvoeren?</li>
                    </ul>
                    <br />
                    <p>Het minimale aantal uren is meestal <b>minimaal 15 werkuren per maand</b>.</p>
                    <p>Potentiële klanten moeten vertrouwen op het oordeel van de SEO-expert over hoe sterk de concurrentie is, welke site-optimalisatie vereist is en welke kansen er zijn.</p>
                    <p>Lees verder hieronder waar ik uitleg <Link to="/nl/seo-freelancer#how-to-hire-the-right-freelancer">hoe je de juiste seo freelancer inhuurt</Link>.</p>
                    <p>Om de prijs te bepalen, wordt de omvang van het contract bij de start van het project gedefinieerd op basis van de bovenstaande factoren.</p>
                    <p>Voor <b>doorlopende SEO-optimalisatie</b> wordt de maandelijkse projectomvang gebaseerd op taken en mijlpalen. Op basis van het uurtarief, geplande SEO-inspanningen en benodigde werktijd zijn de SEO-kosten planbaar en voor elke maand begrensd.</p>
                    <p>Na een overeenkomst kan ik mijn werk beginnen zodra ik toegang krijg tot de website en gerelateerde tools.</p>
                    <p>Ik lever <b>SEO-rapporten</b> in de vorm van een live dashboard zodat u volledige transparantie heeft over de keyword rankings en het algemene succes van mijn SEO-dienst. Ik betaal zelf voor verschillende SEO-tools en neem deze op in het tarief.</p>
                    <p>SEO-klanten hebben soms alleen een <b>klein eenmalig project</b> nodig. Bijvoorbeeld een <b>laadsnelheid optimalisatie</b>, een <b>technische SEO-audit, concurrentieanalyse</b> of het implementeren van belangrijke landingspagina's als <b>AMP-pagina's</b>.</p>
                    <p>Deze kleinere projecten worden individueel ingeschat en zijn natuurlijk niet gerelateerd aan de bovenstaande factoren.</p>


                    <H as="h2">Voordelen van een SEO freelancer vs. een bureau</H>
                    <p>Over het algemeen zijn freelancers een goede keuze voor klanten die <b>weten wat ze willen</b>.</p>
                    <p>Meestal gaat het om een bedrijf dat eerder met een bureau heeft samengewerkt en een deskundige inhouse heeft. Ze hebben minder behoefte aan begeleiding en zoeken liever betrouwbare uitvoering. Ze willen het werk gedaan hebben met een hoge mate van zekerheid zonder te veel presentaties en upsale tactieken.</p>
                    <p>Freelancers leveren precies dat. Ze richten zich op de uitvoering en hebben beperkte tijd voor communicatie.</p>
                    <p>Samenvattend zijn de voordelen van samenwerken met een freelancer als volgt:</p>
                    <ul>
                        <li><b>betere prijs</b></li>
                        <li><b>flexibiliteit</b> omdat je kunt inhuren op basis van behoefte</li>
                        <li>meer jaren <b>ervaring</b></li>
                        <li>meer <b>gespecialiseerde</b> profielen beschikbaar</li>
                        <li><b>communicatie</b> direct met de specialist</li>
                    </ul>

                    <H as="h2">Nadelen</H>
                    <p>De nadelen van werken met een freelancer hebben meestal betrekking op, maar zijn niet beperkt tot, schaalbaarheid. Uiteindelijk werk je met één persoon die maar twee handen heeft. Met een team krijg je meer werk gedaan in een kortere tijd.</p>
                    <p>Andere mogelijke nadelen zijn:</p>
                    <ul>
                        <li><b>Schaalbeperkingen:</b> Als je verwacht dat het aantal taken toeneemt, is één persoon misschien niet genoeg voor de klus.</li>
                        <li><b>Beperkingen door expertise:</b> Een bureau heeft een breder scala aan mensen tot hun beschikking voor advies.</li>
                        <li><b>Betrouwbaarheid:</b> Ziekte of onverwachte levensgebeurtenissen kunnen beter worden gecompenseerd met een team.</li>
                        <li><b>Persoonlijkheids mismatch:</b> Als je niet klikt met je freelancer, moet je misschien verder zoeken en is er tijd verloren. Een bureau kan je in contact brengen met een andere persoon.</li>
                    </ul>

                    <H as="h2">Hoe huur je de juiste freelancer in?</H>
                    <p>Bij het inhuren van SEO freelancers is het belangrijk om de ervaring en SEO vaardigheden van elk profiel te valideren.</p>
                    <p>Je wilt er bijvoorbeeld zeker van zijn dat de technologie van je website overeenkomt met de eerdere projecten van de freelancer. Als je een WordPress-website hebt, zullen de meeste freelancers daar comfortabel mee werken. Maar als je een zeer aangepaste webstack hebt met bijvoorbeeld React of Angular aan de frontend en Sitecore of Umbraco aan de backend, wil je ervoor zorgen dat dit geen uitdagingen oplevert.</p>
                    <p>Individuele SEO-specialisten zijn misschien niet gewend om wijzigingen via Git door te voeren, maar je website vereist dat wel. Bespreek dus het proces van het implementeren van wijzigingen op de website om mogelijke knelpunten te ontdekken.</p>
                    <p>Sommige bedrijven bouwen hun webaanwezigheid zelf met een interne ontwikkelaar, terwijl anderen met een webbureau werken. Dit heeft implicaties voor SEO-advies, aangezien wijzigingen direct kunnen worden doorgevoerd of via een extern bureau moeten verlopen. Dat feit alleen al beïnvloedt de kosten en de benodigde tijd voor een SEO-campagne, omdat er meer communicatielagen nodig zijn totdat een wijziging is doorgevoerd.</p>
                    <p>Onafhankelijke SEO-experts moeten dus comfortabel zijn met de gegeven opzet van interne en externe teams en de impact hiervan op de algehele SEO-strategie in overweging nemen.</p>
                    <p>Ook het trackrecord en waar de freelancer eerder heeft gewerkt spelen een rol bij het trekken van conclusies over werkethiek en betrouwbaarheid. De toetredingsdrempel voor SEO freelancers van Upwork is vrij laag en levert daarom misschien niet de juiste kandidaat op (niet dat het niet mogelijk is). Daarom hoop ik enige transparantie te bieden door mijn{" "}
                        <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
                            Linkedin profiel
                        </a>{" "}
                        vooraf te delen.
                    </p>
                    <p>Als je iemand hebt gevonden, zou een eenvoudige e-mail of ingevuld contactformulier voldoende moeten zijn om een gesprek te starten. Een professionele SEO freelancer neemt echter niet elk project aan dat op zijn pad komt. En dat is goed voor klanten, dus neem een afwijzing niet persoonlijk op.</p>
                    <p>Er zijn verschillende SEO-strategieën voor verschillende niches en zoekintenties. Freelancers willen ervoor zorgen dat de vereisten van de niche en de website technologie overeenkomen met hun expertise.</p>
                    <p>Als dat niet het geval is, moeten klanten sceptisch zijn. Tenzij je een enigszins standaard SEO-taak geleverd wilt hebben, wil je een SEO freelancer vinden die een expert is voor jouw geval.</p>
                    <p>Na schriftelijke overeenstemming over de projectomvang en kosten, heeft de freelancer toegang tot de website nodig. Dat betekent toegang tot de frontend, CMS en analytics en mogelijk een FTP-server. Zodra toegang is verleend, wordt de SEO-expert ingehuurd en begint de werktijd officieel.</p>

                    <H as="h2">Hoe ben ik freelancer in zoekmachineoptimalisatie geworden?</H>
                    <p>Op mijn 14e bouwde ik mijn eerste privé homepage om mijn filmcollectie met schoolvrienden te delen. In ons dorp was ik ook het kind dat de computers repareerde en veel besturingssysteem herinstallaties uitvoerde.</p>
                    <p>
                        Toen ik 19 was, bouwde ik Kickerkult.de, een e-commerce shop voor tafelvoetbal en begon ik zoekmachineoptimalisatie voor mijn eigen zoekwoordonderzoek te doen. Toen was het optimaliseren van meta keywords en title tags bijna voldoende.
                    </p>
                    <p>Prutsen met websites en technologie in het algemeen was altijd leuk voor mij, dus ik moet er natuurlijk voor gekozen hebben om later fulltime in webbureau's en online marketing te werken. De collega's en projecten in die bureaus hadden een goede invloed om mijn vaardigheden uit te breiden en mijn workflows en processen professioneler te maken.</p>
                    <p>Nadat ik genoeg SEO banen had gehad, werd de vooruitgang samen met een digitaal marketingbureau steeds moeilijker, zodat freelance SEO consultancy de logische volgende carrièrestap voor mij was.</p>
                    <p>De meeste <a href="https://www.statista.com/statistics/946967/freelancers-in-europe-by-sector/" target="_blank">freelancers in Europa (39%) werken in de marketing- en communicatiesector</a>. Ik wilde dieper ingaan op mijn onderwerp en bepalen aan welke projecten ik werk, dus freelancen werd de volgende stap om ervoor te zorgen dat elke uur aan een zinvolle taak wordt besteed.</p>
                    <p>Het blijkt dat ik niet helemaal alleen ben met die gedachte. Sterker nog, <a href="https://apo.org.au/sites/default/files/resource-files/2016-05/apo-nid201721.pdf" target="_blank">freelancers of iPros zijn de snelst groeiende groep op de EU arbeidsmarkt sinds 2004</a> en <a href="https://news.malt.com/wp-content/uploads/2019/02/FREELANCING-IN-EUROPE-2-1.pdf" target="_blank">ze vormen ongeveer 7% van de totale beroepsbevolking in de EU</a>.</p>
                    <p>Ik kan nu meer tijd besteden aan de content, zoekwoordonderzoek en linkbuilding, omdat ik minder tijd nodig heb voor administratieve taken of projectmanagement, terwijl mijn klanten meer daadwerkelijk werk voor hun budget krijgen. Freelancen fulltime biedt mij daarom een snellere feedback-loop, wat nodig is om te concurreren en meestal niet haalbaar is in een SEO-bureau.</p>
                    <p>Ik werk sinds 2018 als freelance SEO specialist. Ik werk voornamelijk met zakelijke klanten die multinationale websites hebben, evenals e-commerce winkels volgens een content marketing gedreven aanpak. Ik doe geen lokale SEO.</p>

                    <H as="h3">Op zoek naar SEO hulp?</H>
                    <p>
                        Als u op zoek bent naar een freelance SEO expert om u te helpen met uw Google rankings, neem dan gerust contact
                        met mij op. Ik kom meestal terug met een beoordeling van de situatie en een plan inclusief een offerte.
                    </p>
                    <p>
                        U kunt anders meer informatie vinden op mijn <Link to="/nl/over">over mij pagina</Link> en mogelijk enkele
                        interessante artikelen op mijn <Link to="/nl/blog">blog</Link>.
                    </p>
                    <H as="h4" style={{ "textAlign": "center" }}>Neem contact met mij op voor een vrijblijvende offerte.</H>
                </MainContent>
            </React.Fragment>

        </Layout>
    );
};

export default SeoFreelancer;
