import React, { useContext } from 'react';
import { UIContext } from './UIContext';
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import Img from "./Img.js";
import SkillTag from "./SkillTag";
import CtaPrimary from "./CtaPrimary";
import LocationPin from "./../assets/icons/location-pin.svg"
import CreditCard from "./../assets/icons/credit-card.svg"
import RatingWidget from "../components/RatingWidget";


const StyledProfile = styled.section`

border: 1px solid rgba(0, 0, 0, 0.3);
padding: 1rem;
margin-bottom: 1rem;
display: grid;
grid-template-columns: 1fr 1fr;
.profile-first{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--copy);
    margin: 0 auto;
    & div.name {
        margin-top: 1rem;
    } & div.profession {
        margin-top: 0.5rem;
    }
}
.profile-second{
    width: 100%;
    a {
        margin-bottom: 20rem;
    }
}
.cta-primary {
        display: block;
        margin: 1rem auto 0rem auto;
    }

.details-wrapper {
    margin: 0 auto;

    
    & ul {
        font-family: -apple-system,Arial,'Helvetica','Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans',sans-serif;
    } & .details {
        font-size: 14px;
        color: var(--copy);
        display: grid;
        grid-template-columns: minmax(30px, auto) auto;
        grid-template-rows: auto auto;
        align-items: end;
        justify-content: start;
    } 
}

.profile-image-wrapper {
    
    width: 150px;
    height: 150px;
    & .gatsby-image-wrapper {
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.3);
    }
}


.list-categories {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    & > li {
      margin: 0rem 0.3rem 0.4rem 0rem;
    }
  }

  @media all and (max-width: 570px) {
    grid-template-columns: 1fr;
    .profile-second{
        margin-top: 2rem;
    }
    .details-wrapper {
        max-width: 250px;
    }
    .list-categories {
        /* max-width: 70%; */
    }
  }

`



function ProfileCard({ tags, profession, rate, location, cta, src = "about/matthias-kupperschmidt-front-white-collar.jpeg", alt = "man working on google analytics setup with laptop" }) { // Added src and alt here

    const { language } = useContext(UIContext) // Destructured for direct access

    const links = {
        de: {
            "about": "/de/ueber-mich",
            "contact": "/de/kontakt"
        },
        en: {
            "about": "/en/about",
            "contact": "/en/contact"
        },
        se: {
            "about": "/se/om",
            "contact": "/se/kontakt"
        },
        da: {
            "about": "/da/om-mig",
            "contact": "/da/kontakt"
        },
        no: {
            "about": "/no/om",
            "contact": "/no/contact"
        },
        nl: {
            "about": "/nl/over",
            "contact": "/nl/contact"
        },
        fi: {
            "about": "/fi/tietoa",
            "contact": "/fi/yhteydenotto"
        },
        fr: { // Langue française ajoutée
            "about": "/fr/à-propos",
            "contact": "/fr/le-contact"
        }
    };




    return (
        <StyledProfile className="profile-card">
            <div className="profile-first">
                <div className="profile-image-wrapper">
                    <Link to={links[language].about}>
                        <Img className="profile-image" src={src} alt={alt} /> {/* Use src and alt props here */}
                    </Link>
                </div>
                <div className="sans name"><Link to={links[language].about}><b>Matthias Kupperschmidt</b></Link></div>
                <div className="sans profession">{profession}</div>
                <RatingWidget lang={language} />
            </div>
            <div className="profile-second">
                <div className="details-wrapper">
                    <ul className="list-categories sans">
                        {tags.map(tag => (
                            <SkillTag tag={tag} key={tag} />
                        ))}
                    </ul>
                    <div className="details">
                        <img src={LocationPin} />
                        <div className="location">{location}</div>
                        <img src={CreditCard} />
                        <div>{rate}</div>
                        {/* <div>or from 3000€ monthly</div> */}
                    </div>
                </div>
                <Link to={links[language].contact}><CtaPrimary color="red" arrow="false">{cta}</CtaPrimary></Link>
            </div>
        </StyledProfile>
    );
}

ProfileCard.propTypes = {
    tags: PropTypes.array.isRequired,
    profession: PropTypes.string.isRequired,
    rate: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired, // Add propTypes for src
    alt: PropTypes.string.isRequired, // Add propTypes for alt
    language: PropTypes.string.isRequired // Corrected the placement of propTypes
};

export default ProfileCard;

ProfileCard.propTypes = {
    language: PropTypes.string.isRequired
};