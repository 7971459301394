import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledTag = styled.li`
  list-style: none;
  /* margin: 0rem 0rem 0.4rem 0.3rem; */
  font-size: 12px;
    background: var(--foreground);
    border-radius: 4px;
    white-space: nowrap;
    padding: 0.2rem 0.5rem;


  
`;

const SkillTag = props => {

    return (
        <StyledTag key={props.tag}>

            {props.tag}

        </StyledTag>
    );
}

export default React.memo(SkillTag);