import React from 'react';
import styled from "styled-components";
import Rating45 from "./../assets/icons/stars4-5.svg";

const StyledRating = styled.a`

margin-top: 1rem;
display: grid;
grid-template-columns:1fr auto;
grid-template-rows:1fr 1fr; 

    img {
        margin-right: 0.5rem;
        grid-column: 1 / 1;
        place-self: center end;
    }
    span {
        grid-column: 2 / 2;
        font-size: 1.3rem;
        place-self: center start;
    }
    div {
        grid-column: 1 / span 2;
        place-self: center center;
        font-size: 0.8rem;
    }
`

const RatingWidget = ({
    // defaults as fallback
    lang = "en",
    rating = "4.7",
    reviews = "27",
    link = "https://www.trustpilot.com/review/bluerivermountains.com"
}) => {
    const getReviewText = (lang) => {
        switch (lang) {
            case "de":
                return "Bewertungen  •  Hervorragend";
            case "da":
                return "Anmeldelser  •  Fremragende";
            case "se":
                return "Recensioner  •  Utmärkt";
            case "no":
                return "Anmeldelser  •  Utmerket";
            case "nl":
                return "Beoordelingen  •  Uitstekend";
            default:
                return "Reviews  •  Excellent";
        }
    };

    return (
        <StyledRating className="sans rating" target="_blank" rel="noopener" href={link}>
            <img src={Rating45} alt={`review`} height="30" />
            <span>{rating}/5</span>
            <div>{reviews} {getReviewText(lang)}</div>
        </StyledRating>
    );
};

export default RatingWidget;